<template>
  <div style="margin: 20px auto; min-height: calc(100vh - 100px)">
    <div
      style="
        background: #fff;
        padding: 20px;
        margin-top: 20px;
        height: calc(100vh - 235px);
      "
    >
      <el-button
        v-for="item in partitionData"
        :type="searchType == item.index ? 'primary' : ''"
        @click="searchList($event, item.index)"
        >{{ item.name }}</el-button
      >
      <el-button @click="addDevice" type="">新增设备</el-button>
      <el-button v-if="userType == 1" @click="trafficQuery" type="">流量查询</el-button>
      <el-button v-if="userType == 1" @click="unbundling" type="">机卡解绑</el-button>
      <el-button v-if="userType == 1" @click="retieMachineCard" type=""
        >机卡重绑</el-button
      >

      <div style="float: right">
        <el-input
          style="width: 160px"
          v-model="keyword"
          placeholder="请输入查询关键字"
        ></el-input>
        <el-button @click="searchBtn">搜索</el-button>
      </div>
      <el-table
        ref="tableRef"
        style="width: 1160px; margin-top: 30px"
        :data="tableData"
        height="560"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          :label="item"
          v-for="(item, index) in tableHead"
          :key="index"
          width="148px"
        >
          <template v-if="index == 0" #default="scope">
            {{ scope.row[index] == true ? "在线" : "离线" }}
          </template>
          <template v-else #default="scope">
            {{ scope.row[index] }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button @click="delDevice(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 50px"
        :total="totalNum"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pSize"
        layout="total,->, sizes, prev, pager, next"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <el-dialog title="编辑设备" width="500px" v-model="dialog2">
      <el-form>
        <el-form-item label="分区">
          <el-tree
            show-checkbox
            @check="changetree"
            :check-strictly="true"
            @check-change="handleNodeClick"
            ref="deviceTree"
            node-key="index"
            style="width: 100%"
            :data="areaData"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
        <el-form-item label="有效期">
          <el-date-picker
            v-model="value1"
            value-format="YYYY-MM-DD"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button @click="dialog2 = false" type="">取消</el-button>
        <el-button @click="submitData" type="primary">确认</el-button>
      </template>
    </el-dialog>

    <el-dialog title="新增设备" width="500px" v-model="dialog3">
      <el-form label-width="90px">
        <el-form-item label="起始设备号">
          <el-input v-model="startHao" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="添加数量">
          <el-input v-model="addNum" placeholder=""></el-input>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button @click="subDevice" type="primary">确定</el-button>
          <el-button
            @click="
              dialog3 = false;
              startHao = '';
              addNum = '';
            "
            type=""
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="流量查询" width="500px" v-model="dialog4">
      <el-form label-width="90px">
        <el-form-item label="开始时间">
          <el-input v-model="trafficObj[1]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-input v-model="trafficObj[2]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="IMEI号">
          <el-input v-model="trafficObj[0]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="已用流量">
          <el-input v-model="trafficObj[3]" placeholder=""></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button
            @click="
              dialog4 = false;
              trafficObj = {};
            "
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>

    <el-dialog title="机卡解绑" width="500px" v-model="dialog5">
      <el-form>
        <el-form-item label="ICCID">
          <el-input v-model="cardIccid" placeholder=""></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button
            @click="
              dialog5 = false;
              trafficObj = {};
            "
            >取消</el-button
          >
          <el-button type="primary" @click="jieSub">确认</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="机卡重绑" width="500px" v-model="dialog6">
      <el-form>
        <el-form-item label="ICCID">
          <el-input v-model="cardIccid2" placeholder=""></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button
            @click="
              dialog6 = false;
              trafficObj = {};
            "
            >取消</el-button
          >
          <el-button type="primary" @click="chongSub">确认</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
// 已分配--数据不对要处理
import {
  getDeviceByArea2,
  getDevicesTraffic,
  getAreaDegree2,
  devicesModify,
  getDeviceListAdmin,
  deviceDel,
  deviceAdd,
  unsetDevicesIccid,
  setDevicesIccid,
} from "../utils/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      pSize: 10,
      startHao: "",
      addNum: "",
      pageSize: 0,
      defaultProps: {
        label: "name",
        children: "children",
      },
      partitionData: [],
      areaData: [],
      value1: "",
      tableHead: [
        "在线状态",
        "所有人",
        "注册时间",
        "到期时间",
        "ICCID号",
        "IMEI号",
        "MAC地址",
        "固件版本",
      ],
      tableData: [],
      searchType: -1,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      trafficObj: [],
      editData: [],
      totalNum: 0,
      pageNum: 1,
      cardIccid: "",
      cardIccid2: "",

      userType: null,
      keyword: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    searchBtn() {
      if (this.keyword) {
        this.pageNum = 1;
        this.pageSize = 10;
        this.totalNum = this.tableAllList.filter(
          (res) => res[1].indexOf(this.keyword) > -1
        ).length;
        this.tableData = this.tableAllList
          .filter((res) => res[1].indexOf(this.keyword) > -1)
          .slice(0, 10);
          console.log(this.tableAllList);
      } else {
        this.getList(this.searchType);
      }
    },
    jieSub() {
      let cheTab = JSON.parse(JSON.stringify(this.$refs.tableRef.getSelectionRows()));
      if (!this.cardIccid) {
        ElMessage({
          type: "error",
          message: "请输入ICCID",
          customClass: "mzindex",
        });
        return false;
      }
      unsetDevicesIccid({ params: [cheTab[0][5], this.cardIccid] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "解绑成功",
            customClass: "mzindex",
          });
          this.dialog6 = false;
          this.getList(this.searchType);
        }
      });
    },
    chongSub() {
      let cheTab = JSON.parse(JSON.stringify(this.$refs.tableRef.getSelectionRows()));
      if (!this.cardIccid2) {
        ElMessage({
          type: "error",
          message: "请输入ICCID",
          customClass: "mzindex",
        });
        return false;
      }
      setDevicesIccid({ params: [cheTab[0][5], this.cardIccid] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "解绑成功",
            customClass: "mzindex",
          });
          this.dialog6 = false;
          this.getList(this.searchType);
        }
      });
    },
    subDevice() {
      deviceAdd({
        params: [this.startHao, Number(this.addNum)],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "新增成功",
            customClass: "mzindex",
          });
          this.dialog3 = false;
          this.getList(this.searchType);
        }
      });
    },
    delDevice(row) {
      deviceDel({ params: [row[1]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "删除成功",
            customClass: "mzindex",
          });
          this.getList(this.searchType);
        }
      });
    },
    addDevice() {
      this.dialog3 = true;
    },

    changetree(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.deviceTree.setCheckedKeys([data.index]);
      }
    },
    handleNodeClick(data, checked, node) {
      if (checked) {
        this.$refs.deviceTree.setCheckedKeys([data.index]);
      }
    },
    submitData() {
      let deviceD = this.$refs.deviceTree.getCheckedNodes().map((m) => {
        return m.index;
      });
      if (!deviceD || deviceD.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择分区",
          customClass: "mzindex",
        });
        return false;
      }
      devicesModify({
        params: [this.editData[2], deviceD[0], this.value1],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "error",
            message: "编辑成功！",
            customClass: "mzindex",
          });
          this.dialog2 = false;
        }
      });
    },
    handleEdit(row) {
      this.dialog2 = true;
      this.editData = JSON.parse(JSON.stringify(row));
    },
    currentChange(val) {
      this.pageNum = val;
      if (this.keyword) {
        this.tableData = this.tableAllList
          .filter((f) => f[1].indexOf(this.keyword) > -1)
          .slice(this.pSize * (val - 1), this.pSize * val);
      } else {
        this.tableData = this.tableAllList.slice(
          this.pSize * (val - 1),
          this.pSize * val
        );
      }
    },
    handleSizeChange(val) {
      this.pSize = val;
      if (this.keyword) {
        this.tableData = this.tableAllList
          .filter((f) => f[1].indexOf(this.keyword) > -1)
          .slice(val * (this.pageNum - 1), val * this.pageNum);
      } else {
        this.tableData = this.tableAllList.slice(
          val * (this.pageNum - 1),
          val * this.pageNum
        );
      }
    },
    getList(a = -1) {
      getDeviceListAdmin({ params: [a, -1] }).then((res) => {
        if (!res.error) {
          this.tableAllList = res.result[0];
          this.tableData = res.result[0].slice(0, 10);
          this.totalNum = res.result[1];
          this.pageSize = res.result[2];
        } else {
          this.tableAllList = [];
          this.tableData = [];
        }
        this.$forceUpdate();
      });
    },
    searchList(e, item) {
      this.pSize = 10;
      this.pageNum = 1;
      e.preventDefault();
      this.searchType = item;
      this.dialog1 = true;
      getDeviceListAdmin({ params: [this.searchType, -1] }).then((res) => {
        if (!res.error) {
          this.tableAllList = res.result[0];
          this.tableData = res.result[0].slice(0, 10);
          this.totalNum = res.result[1];
          this.pageSize = res.result[2];
        }
      });
    },
    trafficQuery() {
      let cheTab = JSON.parse(JSON.stringify(this.$refs.tableRef.getSelectionRows()));
      if (cheTab && cheTab.length == 1) {
        this.dialog4 = true;
        getDevicesTraffic({ params: cheTab.map((m) => m[5]) }).then((res) => {
          // “result”:[ID,开始日期，结束日期，已用流量]
          this.trafficObj = res.result;
        });
      } else {
        ElMessage({
          message: "请选择设备且只能选择单条查询！",
          type: "error",
          customClass: "mzindex",
        });
      }
    },

    unbundling() {
      //解绑
      let cheTab = JSON.parse(JSON.stringify(this.$refs.tableRef.getSelectionRows()));
      if (cheTab && cheTab.length == 1) {
        this.dialog5 = true;
      } else {
        ElMessage({
          message: "请选择设备且只能选择单条查询！",
          type: "error",
          customClass: "mzindex",
        });
      }
    },
    retieMachineCard() {
      //重绑
      let cheTab = JSON.parse(JSON.stringify(this.$refs.tableRef.getSelectionRows()));
      if (cheTab && cheTab.length == 1) {
        this.dialog6 = true;
      } else {
        ElMessage({
          message: "请选择设备且只能选择单条查询！",
          type: "error",
          customClass: "mzindex",
        });
      }
    },
  },
  created() {
    getAreaDegree2({ params: [] }).then((res) => {
      if (!res.error) {
        this.partitionData = res.result ? res.result : [];
      }
    });
    this.getList();

    this.userType = window.localStorage.getItem("userType");
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
